<template>
  <div class="verify-page">
    <div class="verify-group">
      <yd-cell-group>
        <yd-cell-item>
          <span slot="left">新手机号</span>
          <yd-input
						slot="right"
						v-model="mobile"
						required
						:show-success-icon="false"
						:show-error-icon="false"
						:show-clear-icon="false"
            regex="mobile"
						placeholder="11位手机号码"
					></yd-input>
        </yd-cell-item>
      </yd-cell-group>
      <div class="df fdr alc verify-item border-bottom-1px">
        <span class="yd-cell-left">图片验证</span>
        <div class="verify-right">
          <yd-input
						v-model="code"
						required
						:show-clear-icon="false"
						:show-success-icon="false"
						:show-error-icon="false"
						placeholder="输入图片验证码"
						max="6"
					></yd-input>
        </div>
        <img
					class="verify-img"
					:src="img_code"
					@click.prevent="getRandomImgCode" 
				/>
      </div>
      <div class="df fdr alc jc-sb verify-item border-bottom-1px">
        <div class="df fdr">
          <span class="yd-cell-left">验证码</span>
          <div class="verify-right">
            <yd-input
							v-model="verify_code"
							required
							:show-clear-icon="false"
							:show-success-icon="false"
							:show-error-icon="false"
							placeholder="验证码" max="6"
						></yd-input>
          </div>
        </div>
        <yd-sendcode
					slot="right"
					v-model="start"
					@click.native="sendCode"
					type="warning"
					storage-key="storage"
					second="30"
        ></yd-sendcode>
      </div>
    </div>
    <div class="save-box">
      <button
				class="save-btn"
				:disabled="is_disabled"
				@click="submit"
			>确认新绑定</button>
    </div>
		
    <!-- S 提示弹窗 -->
    <alert-tip
			:type="type"
			:show-alert="show_tip"
			:alert-text="tip"
			@changeShowAlert="changeShow"
		></alert-tip>
    <!-- E 提示弹窗 -->
  </div>
</template>

<script>
import Validator from "@/utils/validator";
import {debounce} from "@/utils/debounce";
import {getImgCode, sendVerifyCode} from "@/services";
import {verifyMobile, bindNewMobile, bindMobile} from "@/services/my";
import AlertTip from "@/components/common/AlertTip";
import {toolTip, inputMixins} from "@/mixins";
import {mapState} from 'vuex'

let base_code = '';
export default {
  name: "VerifyPhone",
	beforeRouteEnter (to, from, next) {
		if (from.name === 'verify_phone') {
			if(sessionStorage.getItem('phoneStatus') === 'ok') {
				next();
			} else {
				history.go(-1);
			}
		} else {
			history.go(-1);
		}
	},
  data() {
    return {
      is_pwd: false,//是否从1->3
      mobile: "", // 手机号码
      code: "", // 验证码
      img_code: "", // 图形验证码
      verify_code: "", // 短信验证码
      old_phone: '',
      cut_num: 60,
      show_cut: true,
      start: false,
    }
  },
  components: {
    AlertTip,
  },
  mixins: [toolTip, inputMixins],
  computed: {
    //按钮是否可点击
    is_disabled() {
      if (this.mobile && this.code && this.verify_code)
        return false;
      else return true
    },
    ...mapState({
      //手机号码
      phone: state => state.userInfo.mobile ? state.userInfo.mobile : '',
    }),
  },
	destroyed() {
		sessionStorage.removeItem('phoneStatus');
	},
  methods: {
    getParams() {
      document.title = "换绑手机号";
    },

    // 获取图形验证码
    async loadImgCode() {
      try {
        const res = await getImgCode();
        if (res.code === 0) {
          this.img_code = res.data.url;
          base_code = res.data.url;
        } else {
					this.$dialog.toast({
						mes: res.msg
					});
        }
      } catch (e) {
				// TODO handle the exception
      }
    },

    // 切换图形验证码
    getRandomImgCode: debounce(function () {
      this.img_code = base_code + "&" + Math.random();
    }, 300),

    /**
     * 获取验证码
     */
    async sendCode() {
      let mobile = this.mobile.replace(/\s+/g, ""),
					code = this.code.replace(/\s+/g, "");
      let scene = 'register';
      const {result, errorMessage} = Validator.validate("mobile", mobile);

      if (!mobile) {
        this.show_layer("cancel", "请输入手机号码");
        return;
      }
      if (!result) {
        this.show_layer("cancel", errorMessage);
        return;
      }
      if (!code) {
        this.show_layer("cancel", "请输入图形验证码");
        return;
      }
      this.$dialog.loading.open('发送中...');

      const res = await sendVerifyCode(mobile, scene, code);
      if (res.code === 0) {
        this.start = true;
        this.$dialog.loading.close();
        this.show_layer("success", "验证码已发送，请注意查收");
      } else {
        this.$dialog.loading.close();
        this.$dialog.toast({
					mes: res.msg
				});
			}
    },

    // 提交表单
    submit: debounce(async function () {
      let mobile = this.mobile.replace(/\s+/g, ""),
					code = this.code.replace(/\s+/g, ""),
					verify_code = this.verify_code.replace(/\s+/g, "");
      const {result, errorMessage} = Validator.validate("mobile", mobile);

      if (!mobile) {
        this.show_layer("cancel", "请输入手机号码");
        return;
      }
      if (!result) {
        this.show_layer("cancel", errorMessage);
        return;
      }
      if (!code) {
        this.show_layer("cancel", "请输入图形验证码");
        return;
      }
      if (!verify_code) {
        this.show_layer("cancel", "请输入验证码");
        return;
      }
	
      //换绑手机号
      try {
        const res = await bindNewMobile(mobile, verify_code);
        if (res.code === 0) {
          this.$dialog.toast({
            mes: '换绑手机号成功',
            icon: 'success',
            timeout: 1500
          });
          this.$router.go(-1)
        } else{
          this.$dialog.toast({
						mes: res.msg
					});
        }
      } catch (e) {
        console.log(e);
      }
    }, 300)
  },
  mounted() {
    this.getParams();
    this.loadImgCode();
  }
}
</script>

<style lang="less">
@import "../../../../common/less/variable.less";
@import "../../../../common/less/mixin.less";

.verify-page {
  .verify-group {
    background: @color-white;
    padding-right: 0.22rem;
    padding-left: 0.26rem;

    .yd-cell-item {
      padding-left: 0;
    }

    .yd-cell-box {
      margin-bottom: 0;
    }

    .yd-cell-left {
      width: 1.6rem;
      .sc(0.32rem, #000);
    }

    .yd-cell-right {
      .wh(3.9rem, 1.18rem);
      padding-right: 0;
    }

    input {
      .sc(0.32rem, @color-dark-grey);

      input::placeholder {
        .sc(0.32rem, @color-light-grey);
      }
    }

    .verify-item {
      .wh(100%, 1rem);

      .verify-right {
        .wh(3rem, 1rem);

      }

      .yd-btn-warning:not(.yd-btn-loading) {
        background: #fff;
        .sc(0.28rem, var(--main-color));
      }

      .yd-btn-disabled {
        background: #fff;
        .sc(0.28rem, @color-light-grey);
      }

      .yd-btn {
        padding: 0 0.1rem;
      }
    }

    .verify-img {
      height: 0.8rem;
      min-width: 1.2rem;
      position: absolute;
      right: 0.12rem;
      width: 2.4rem;
    }

    .verify-num {
      position: absolute;
      right: 0.12rem;
      .sc(0.28rem, var(--main-color));
    }

    .again {
      .sc(0.28rem, @color-light-grey);
    }
  }


  /*保存按钮*/

  .save-box {
    .wh(100%, 0.88rem);
    padding: 0 0.24rem;
    margin-top: 0.5rem;

    .save-btn {
      .wh(100%, 100%);
      background: var(--main-color);
      border-radius: 1rem;
      line-height: 0.88rem;
      .sc(0.32rem, #fff);
      text-align: center;
    }

    button {
      border: 0;
    }

    button[disabled] {
      opacity: 0.4;
    }
  }

}

</style>
